
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import dayjs from "dayjs";
const marketing = namespace("marketing");

@Component({
  computed: {
    dayjs() {
      return dayjs
    }
  }
})
export default class TestItemEdit extends Vue {
  @marketing.Action queryCardTemplateDetail;
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  get breadData() {
    return [
      { name: this.$t("marketing.card-template"), path: "/management/card-template" },
      { name: this.$t("marketing.template-detail") }
    ];
  }
  get checkTemplateId() {
    return this.$route.params.id;
  }
  created() {
    this.init();
  }
  cardTemplateInfo: any = {};
  groupIndex: number = 1;
  init() {
    this.queryCardTemplateDetail({
      cardTemplateCode: this.checkTemplateId
    }).then(data => {
      data = data.data || {};
      data.serviceList.forEach(serviceItem => {
        if (serviceItem.partsList && serviceItem.partsList.length > 0) {
          serviceItem.isGroup = true;
          serviceItem.groupServiceIndex = this.groupIndex;
          serviceItem.partsList.forEach(partsItem => {
            data.partsList.push(
              Object.assign(partsItem, {
                isGroup: true,
                groupServiceIndex: this.groupIndex
              })
            );
          });
          this.groupIndex++;
        }
      });
      this.cardTemplateInfo = data;
    });
  }
  formatSource(val){
      if(val === 2){
        return '抖音'
      }else if(val == 3){
        return '美团'
      }else if(val == 7){
        return '快手'
      }else{
        return '-'
      }
  }
}
